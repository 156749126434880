import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import employee from '../images/employerWork.jpg';

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'space-between',
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 20,
      marginBottom: 0,
    },
  },
  imageGrid: {
    padding: '0px 0px 40px 100px',
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
    },
  },
  textGrid: {
    padding: '0px 0px 40px 160px',
    maxWidth: 720,
    '@media (max-width:1024px)': {
      padding: '0px 0px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '0px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: 0, paddingRight: 0, marginTop: 100 }}
    >
      <Grid
        container
        direction="column"
        styles={{ paddingLeft: 0, paddingRight: 0 }}
      />
      <Grid container className={classes.container}>
        <Grid item sm={6} className={classes.textGrid}>
          <h3 style={{ marginBlockStart: 0 }}>Thank you for your applying</h3>
        </Grid>
        <Grid item sm={6} className={classes.imageGrid}>
          <img src={employee} alt="employee" />
        </Grid>
      </Grid>
    </Container>
  );
};
